html,
body, #root, .fill {
    margin: 0;
    border: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.has-edit-icon{
    min-height: 35px;
}

.MuiStepConnector-lineVertical{
    min-height: 2px !important;
}

